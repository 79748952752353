import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Spin, Result } from 'antd'; // Using Ant Design for spinner and message display
import { upgradeUser, verifyUserEmail } from '../api/services/User';

const SubscribeSuccess: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const sessionId = searchParams.get('session_id');

    if (sessionId ) {
      upgradeUser.call(sessionId)
        .then(() => {
          setVerified(true);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message || 'Upgrade failed.');
          setLoading(false);
        });
    } else {
      setError('Invalid URL parameters.');
      setLoading(false);
    }
  }, [searchParams]);

  if (loading) {
    return (
      <div style={styles.centeredContainer}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={styles.centeredContainer}>
      {error ? (
        <Result status="error" title="An error occurred during upgrade. Please contact admin." subTitle={error} />
      ) : (
        <Result
          status="success"
          title="Upgraded Account Successfully"
          extra={
            <Link to="/signin">
              Continue to Login
            </Link>
          }
        />
      )}
    </div>
  );
};

const styles = {
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center' as const,
  },
};

export default SubscribeSuccess;