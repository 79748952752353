import React, { useState, useEffect } from 'react';
import { Tabs, Tree, Button, Modal, Input, Form, Checkbox, message, AutoComplete, Switch } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Prompt, PromptSource, PromptStatus, PromptVisibility, listPrompts, upsertPrompt } from '../api/services/User';

const { TabPane } = Tabs;

interface TreeNode {
    title: string;
    key: string | number;
    children?: TreeNode[];
    type: string;
    prompt: Prompt | undefined;
}

const PromptsContent: React.FC = () => {
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const [groupOptions, setGroupOptions] = useState<string[]>([]);

    useEffect(() => {
        fetchPrompts();
    }, []);

    const fetchPrompts = async () => {
        try {
            const response = await listPrompts.call();
            setPrompts(response.prompts);
            const groups = Array.from(new Set(response.prompts.map(prompt => prompt.group).filter(Boolean)));
            setGroupOptions(groups);
        } catch (error) {
            message.error('Failed to load prompts');
        }
    };

    const handleAddPrompt = async () => {
        setSelectedPrompt(null);
        setIsEditing(false);
        form.resetFields();
        setModalVisible(true);
    };

    const handleEditPrompt = async (prompt: Prompt) => {
        setSelectedPrompt(prompt);
        setIsEditing(true);
        form.setFieldsValue(prompt);
        setModalVisible(true);
    };

    const handleDeletePrompt = async (prompt: Prompt) => {
        let updatedPrompt: Prompt = { ...prompt, status: PromptStatus.DELETED_PROMPT };
        await upsertPrompt.call(updatedPrompt);
        message.success('Prompt deleted');
        fetchPrompts();
    };

    const handleSave = async (values: any) => {
        const prompt = {
            ...selectedPrompt,
            ...values,
            visibility: values.visibility ? PromptVisibility.PRIVATE : PromptVisibility.PUBLIC,
            status: PromptStatus.ACTIVE_PROMPT,
        };
        await upsertPrompt.call(prompt);
        message.success('Prompt saved');
        setModalVisible(false);
        fetchPrompts();
    };

    const renderPromptTree = (source: PromptSource) => {
        const filteredPrompts = prompts.filter(p => p.promptSource === source);
        const groupedPrompts = filteredPrompts.reduce((acc: Record<string, TreeNode[]>, prompt: Prompt) => {
            const groupKey = prompt.group || 'Ungrouped'; // Handle case where group is undefined
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push({
                title: prompt.title,
                key: prompt.id!,
                type: "prompt",
                prompt: prompt
            });
            return acc;
        }, {});

        const treeData: TreeNode[] = Object.keys(groupedPrompts).map(group => ({
            title: group,
            key: group,
            children: groupedPrompts[group],
            type: "group",
            prompt: undefined
        }));

        return (
            <Tree
                treeData={treeData}
                titleRender={(node) => (
                    <span>
                        {node.title}
                        {node.key && node.type === "prompt" && node.prompt?.promptSource === PromptSource.USER_GENERATED && (
                            <>
                                <EditOutlined
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        const prompt = filteredPrompts.find(p => p.id === node.key);
                                        if (prompt) handleEditPrompt(prompt);
                                    }}
                                />
                                <DeleteOutlined
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        const prompt = filteredPrompts.find(p => p.id === node.key);
                                        if (prompt) {
                                            Modal.confirm({
                                                title: 'Confirm Deletion',
                                                content: `Are you sure you want to delete the prompt "${prompt.title}"?`,
                                                okText: 'Yes',
                                                cancelText: 'No',
                                                onOk: () => handleDeletePrompt(prompt),
                                            });
                                        }
                                    }}
                                />
                            </>
                        )}
                    </span>
                )}
            />
        );
    };

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="My Prompts" key="1">
                    <Button type="primary" onClick={handleAddPrompt} style={{ marginBottom: 16 }}>
                        Add Prompt
                    </Button>
                    {renderPromptTree(PromptSource.USER_GENERATED)}
                </TabPane>
                <TabPane tab="System Prompts" key="2">
                    {renderPromptTree(PromptSource.SYSTEM)}
                </TabPane>
            </Tabs>

            <Modal
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={() => form.submit()}
                title={isEditing ? 'Edit Prompt' : 'Add Prompt'}
                width={600} // Optional: set a custom width if needed
            >
                <Form
                    form={form}
                    onFinish={handleSave}
                    initialValues={{
                        ...selectedPrompt,
                        visibility: selectedPrompt?.visibility === PromptVisibility.PRIVATE, // Convert enum to boolean
                    }}
                    layout="vertical" // Ensure labels are above the fields
                >
                    <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                        <Input.TextArea rows={5} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="group" label="Group" rules={[{ required: true }]}>
                        <AutoComplete
                            options={groupOptions.map(option => ({ value: option }))}
                            style={{ width: '100%' }}
                            placeholder="Type to search or add new"
                        >
                            <Input />
                        </AutoComplete>
                    </Form.Item>
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <span style={{ marginRight: 8 }}>Make Private</span>
                        <Form.Item
                            name="visibility"
                            valuePropName="checked"
                            initialValue={false} // Default to false if not set
                            style={{ marginLeft: 10, flexGrow: 1 }}
                        >
                            <Switch />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default PromptsContent;