import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter
import "./App.css"; // import the CSS file
import { lazy, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { Box } from "@mui/material";
import SignUp from "./home/SignUp";
import SignIn from "./home/SignIn";
import VerifyMail from "./home/VerifyMail";
import SubscribeSuccess from "./home/SubscribeSuccess";
import PaymentCancel from "./home/PaymentCancel";
import Home from "./home/Home";

function App() {
  const [isOobePage, setIsOobePage] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname;

    // Check if the path contains "/signin" or "/signup"
    if (pathname.includes("/signin") || pathname.includes("/signup") || pathname.includes("/verify_")) {
      setIsOobePage(true);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          controlItemBgActive: "#e5e6f1",
          colorSuccess: "black",
        },
      }}
    >
      <Router> {/* Wrap everything inside the Router */}
        <Box
          key={"flex-container"}
          position={"absolute"}
          top={0}
          left={0}
          bottom={0}
          right={0}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box key={"flex-column"} display={"flex"} flexDirection={"column"} flexGrow={1} minHeight={0}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/verify_mail" element={<VerifyMail />} />
              <Route path="/subscribe_success" element={<SubscribeSuccess />} />
              <Route path="/payment_cancel" element={<PaymentCancel />} />
              <Route path="/signup" element={<SignUp />} />
            </Routes>
          </Box>
        </Box>
      </Router> {/* Close the Router */}
    </ConfigProvider>
  );
}

export default App;