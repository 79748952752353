// src/home/SignIn.tsx
import React, { useState } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Google } from '@mui/icons-material';
import FirebaseService from '../auth/Firebase'; // Adjust the import path as needed
import { Link } from 'react-router-dom';
import { GetDetailedUserInfoResponse, createUser, getDetailedUserInfo } from '../api/services/User';

const GoogleSignInButton = styled(Button)({
    background: 'white',
    borderRadius: '4px',
    border: '1px solid #e0e0e0',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
        background: '#f1f1f1',
    },
});

const GoogleSignInIcon = styled(Google)({
    marginRight: '8px',
});

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);

    const handleSignIn = async () => {
        try {
            await FirebaseService.signInWithEmailAndPassword(email, password);
            window.location.href = '/'; // Redirect after sign-in
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Error signing in. Please check your credentials.");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, "", String(emailVerified), "GOOGLE", uid);
        if (userResponse.error) {
            setIsErrorMessage(true);
            setMessage("Error signing up user with Google.");
        } else {
            window.location.href = BASE_APP_URL + "/";
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            // Sign in with Google using Firebase
            const userCredential = await FirebaseService.signInWithGoogle();
            const { user } = userCredential;

            if (user) {
                const { email, displayName, emailVerified, uid } = user;

                // Check if the user exists on your service
                const existingUser: GetDetailedUserInfoResponse = await getDetailedUserInfo.userResponse();

                if (!existingUser.userInfo?.email) {
                    // Call signupUser if the user doesn't exist on your service
                    await saveGoogleSignedUpUser(email!, displayName || "", emailVerified, uid);
                }

                // Redirect after successful sign-in
                window.location.href = BASE_APP_URL + "/";
            }
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            setIsErrorMessage(true);
            setMessage("Unable to sign in with Google.");
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            padding="20px"
        >
            <Box
                p={4}
                bgcolor="white"
                borderRadius={4}
                boxShadow={3}
                maxWidth={400}
                width="100%"
                textAlign="center"
            >   <Typography variant="h5" gutterBottom>
                    Sign In
                </Typography>

                <TextField
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleSignIn}
                    disabled={!email || !password}
                >
                    Sign In
                </Button>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Or
                </Typography>
                <GoogleSignInButton
                    fullWidth
                    onClick={handleGoogleSignIn}
                    startIcon={<GoogleSignInIcon />}
                >
                    Sign In with Google
                </GoogleSignInButton>
                <Link to="/signup">
                    <Typography variant="body2" color="textSecondary" marginTop="16px">
                        Don't have an account? Create an account.
                    </Typography>
                </Link>
                {isErrorMessage && (
                    <Typography color="error" variant="body2">
                        {message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default SignIn;