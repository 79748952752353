import React from 'react';
import { Modal, Button, Card, Row, Col } from 'antd';
import { DollarOutlined } from '@ant-design/icons';

interface UpgradeModalProps {
  visible: boolean;
  onCancel: () => void;
  proUpgradeLink: string;
  eliteUpgradeLink: string;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ visible, onCancel, proUpgradeLink, eliteUpgradeLink }) => {
  return (
    <Modal
      title="Upgrade Your Account"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Row gutter={[16, 16]}>
        {/* Pro Tier */}
        {proUpgradeLink && (
          <Col xs={24} md={12}>
            <Card
              title="Pro"
              bordered={true}
              extra={<DollarOutlined style={{ fontSize: '24px', color: '#4CAF50' }} />}
            >
              <h3>$4.99 / month</h3>
              <ul>
                <li>2000 rewrites per month</li>
                <li>2000 word limit per rewrite</li>
              </ul>
              <Button type="primary" href={proUpgradeLink} block>
                Upgrade to Pro
              </Button>
            </Card>
          </Col>)}

        {/* Elite Tier */}
        <Col xs={24} md={12}>
          <Card
            title="Elite"
            bordered={true}
            extra={<DollarOutlined style={{ fontSize: '24px', color: '#FF5722' }} />}
          >
            <h3>$9.99 / month</h3>
            <ul>
              <li>10,000 rewrites per month</li>
              <li>5000 word limit per rewrite</li>
            </ul>
            <Button type="primary" href={eliteUpgradeLink} block>
              Upgrade to Elite
            </Button>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default UpgradeModal;