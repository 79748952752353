import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Typography, Card, Spin, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { AccountInfo, GetAccountInfoResponse, UserTier, getAccountInfo, getDetailedUserInfo, getPaymentMethodSetupUrl } from '../api/services/User';
import UpgradeModal from './UpgradeModal';

const { Title, Text } = Typography;

const AccountContent: React.FC = () => {
    const [apiKey, setApiKey] = useState('your-api-key-here');
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [loading, setLoading] = useState(true);
    const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
    const [proPaymentLink, setProPaymentLink] = useState('');
    const [elitePaymentLink, setElitePaymentLink] = useState('');
    const [userName, setUserName] = useState<string>('User');

    useEffect(() => {
        fetchUserInfo();
        fetchAccountInfo();
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await getDetailedUserInfo.userResponse();
            const name = response.userInfo.name.split(' ')[0];
            setApiKey(response.userInfo.apiKey);
            setLoading(false);
            setUserName(response.userInfo.name.split(" ")[0]);
        } catch (error) {
            message.error('Failed to load user information');
            setLoading(false);
        }
    };

    const fetchAccountInfo = async () => {
        try {
            const response: GetAccountInfoResponse = await getAccountInfo.call();
            setAccountInfo(response.accountInfo);
            setLoading(false);
        } catch (error) {
            message.error('Failed to load account information');
            setLoading(false);
        }
    };


    const fetchPaymentMethodSetupUrls = async () => {
        try {
            const response = await getPaymentMethodSetupUrl.call();
            setProPaymentLink(response.proPaymentMethodSetupUrl);
            setElitePaymentLink(response.elitePaymentMethodSetupUrl);
        } catch (error) {
            message.error('Failed to load payment setup URLs');
        }
    };


    const handleUpgradeClick = async () => {
        await fetchPaymentMethodSetupUrls();
        setUpgradeModalVisible(true);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(apiKey);
        message.success('API Key copied to clipboard');
    };

    const getTierTitle = (tier: UserTier | undefined) => {
        if (!tier) return '';
        if (tier === UserTier.FREE_TIER) {
            return "Free Trial";
        }
        if (tier === UserTier.PRO_TIER) {
            return "Pro Tier";
        }
        if (tier === UserTier.ELITE_TIER) {
            return "Elite Tier";
        }
    };

    const timestamp = accountInfo?.quotaRefreshTimeMillis || 0;
    console.log('Timestamp:', timestamp);
    var date= new Date(timestamp*1);
    const isValidDate = !isNaN(date.getTime());
    const formattedDate = isValidDate
      ? date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit'
        })
      : 'Invalid Date';
    
    return (
        <Spin spinning={loading}>
            <Title level={3}>Welcome {userName}</Title>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card bordered>
                        <Title level={4}>API Key</Title>
                        <Row>
                            <Col span={20}>
                                <Input value={apiKey} readOnly />
                            </Col>
                            <Col span={4}>
                                <Button icon={<CopyOutlined />} onClick={copyToClipboard}>
                                    Copy
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24} style={{ marginTop: '20px' }}>
                    <Card bordered>
                        <Title level={4}>Account Information</Title>
                        <p>
                            Tier: <Text strong>{getTierTitle(accountInfo?.tier)}</Text>
                        </p>
                        {accountInfo?.tier === 'FREE_TIER' && (
                            <p>Remaining Free Trial Days: {accountInfo.remainingFreeTrialDays}</p>
                        )}
                        <p>Remaining Rewrites: {accountInfo?.remainingRewrites}</p>
                        {accountInfo?.tier != 'FREE_TIER' && (<p>
                            Quota Refreshes on:{' '}
                            {formattedDate}
                        </p>)}
                        {(accountInfo?.tier === UserTier.FREE_TIER ||
                            accountInfo?.tier === UserTier.PRO_TIER) && (
                                <Button type="primary" onClick={handleUpgradeClick}>
                                    Upgrade
                                </Button>
                            )}
                    </Card>
                </Col>
            </Row>

            <UpgradeModal
                visible={upgradeModalVisible}
                onCancel={() => setUpgradeModalVisible(false)}
                proUpgradeLink={proPaymentLink}
                eliteUpgradeLink={elitePaymentLink}
            />
        </Spin>
    );
};

export default AccountContent;