import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Spin, Result } from 'antd'; // Using Ant Design for spinner and message display
import { upgradeUser, verifyUserEmail } from '../api/services/User';

const PaymentCancel: React.FC = () => {




  return (
    <div style={styles.centeredContainer}>

        <Result
          status="info"
          title="Payment Cancelled"
          extra={
            <Link to="/signin">
              Continue to Login
            </Link>
          }
        />
    </div>
  );
};

const styles = {
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center' as const,
  },
};

export default PaymentCancel;