import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, styled } from '@mui/material';
import { Google } from '@mui/icons-material';
import { createUser } from '../api/services/User';
import FirebaseService from '../auth/Firebase';
import { UserCredential, getAdditionalUserInfo, AdditionalUserInfo } from 'firebase/auth';
import { Link } from 'react-router-dom';

const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL;

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [preferredName, setPreferredName] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [isSignUpButtonEnabled, setIsSignUpButtonEnabled] = useState(false);

    useEffect(() => {
        updateSignUpButtonEnableStatus();
    }, [email, preferredName, password]);

    const handleSignUp = async () => {
        const isEmailVerified = "false";
        const provider = "EMAIL";
        const userResponse = await createUser.signupUser(email, preferredName, password, isEmailVerified, provider, "");
        if (userResponse.error) {
            if (userResponse.failureReason === "USER_FOR_EMAIL_ALREADY_EXISTS") {
                setIsErrorMessage(true);
                setMessage("Email is already registered. Please sign in instead.");
            } else {
                setIsErrorMessage(true);
                setMessage("Error signing up user.");
            }
        } else {
            setIsErrorMessage(false);
            setMessage("Account created successfully. Check your email to verify your account.");
        }
    };

    const handleGoogleSignUp = async () => {
        try {
            const result: UserCredential = await FirebaseService.signInWithGoogle();
            const additionalUserInfo: AdditionalUserInfo | null = getAdditionalUserInfo(result);
            if (additionalUserInfo?.isNewUser) {
                const currentUser = await FirebaseService.getCurrentUser();
                if (currentUser && currentUser.email && currentUser.displayName) {
                    saveGoogleSignedUpUser(currentUser.email, currentUser.displayName, currentUser.emailVerified, currentUser.uid);
                }
            } else {
                setMessage("You already have an account. Please sign in.");
            }
        } catch (error) {
            setIsErrorMessage(true);
            setMessage("Unable to sign up with Google.");
        }
    };

    const saveGoogleSignedUpUser = async (currentUserEmail: string, currentUserDispalyName: string, emailVerified: boolean, uid: string) => {
        const userResponse = await createUser.signupUser(currentUserEmail, currentUserDispalyName, "", String(emailVerified), "GOOGLE", uid);
        if (userResponse.error) {
            setIsErrorMessage(true);
            setMessage("Error signing up user with Google.");
        } else {
            window.location.href = BASE_APP_URL + "/";
        }
    };

    const updateSignUpButtonEnableStatus = () => {
        setIsSignUpButtonEnabled(email.length > 0 && password.length > 5 && preferredName.length > 0);
    };

    const GoogleSignInButton = styled(Button)({
        background: 'white',
        borderRadius: '4px',
        border: '1px solid #e0e0e0',
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': {
            background: '#f1f1f1',
        },
    });

    const GoogleSignInIcon = styled(Google)({
        marginRight: '8px',
    });

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="#f5f5f5"
        >
            <Box
                p={4}
                bgcolor="white"
                borderRadius={4}
                boxShadow={3}
                maxWidth={400}
                width="100%"
                textAlign="center"
            >
                <Typography variant="h5" gutterBottom>
                    Sign Up
                </Typography>
                <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Preferred Name"
                    margin="normal"
                    value={preferredName}
                    onChange={(e) => setPreferredName(e.target.value)}
                />
                <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {message && (
                    <Typography
                        color={isErrorMessage ? 'error' : 'primary'}
                        variant="body2"
                        sx={{ mt: 1 }}
                    >
                        {message}
                    </Typography>
                )}
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    disabled={!isSignUpButtonEnabled}
                    onClick={handleSignUp}
                >
                    Sign Up
                </Button>
                <Typography variant="body2" sx={{ mt: 2 }}>
                    Or
                </Typography>
                <GoogleSignInButton
                    fullWidth
                    onClick={handleGoogleSignUp}
                    startIcon={<GoogleSignInIcon />}
                >
                    Sign Up with Google
                </GoogleSignInButton>
                <Link to="/signin">
                    <Typography variant="body2" color="textSecondary" marginTop="16px">
                        Already have an account? Sign in instead
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default SignUp;