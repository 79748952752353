const PROJECT_ID_STORAGE_KEY = "projectId";
const USER_STORAGE_KEY = "user";
const TOKEN_STORAGE_KEY = "token";
const DETAILED_USER_INFO_KEY = "detailedUserInfo";


const LocalStorageManager = {
  clear() {
    localStorage.clear();
  },

  setUser(user) {
    localStorage.setItem(USER_STORAGE_KEY, user);
  },

  removeUser() {
    localStorage.removeItem(USER_STORAGE_KEY);
  },

  getDetailedUserInfo() {
    let storedUserDetail = localStorage.getItem(DETAILED_USER_INFO_KEY) + "";
    return JSON.parse(storedUserDetail);
  },

  getEmailVerificationStatus() {
    return this.getDetailedUserInfo().emailVerificationStatus ?? "UNKNOWN_EMAIL_VERIFICATION_STATUS";
  },

  setDetailedUserInfo(value) {
    localStorage.setItem(DETAILED_USER_INFO_KEY, value);
  },

  setToken(value) {
    localStorage.setItem(TOKEN_STORAGE_KEY, value);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  },

  getToken() {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  },

  setItem(key, value) {
    localStorage.setItem(key, value);
  },

  getItem(key) {
    return localStorage.getItem(key);
  },

};

export default LocalStorageManager;
